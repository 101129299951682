import axiosInstance, {
  axiosWithAuthenticationInstance,
} from "../Utility/axiosInstance";

export const LoginUser = (username, password) => {
  let formData = new FormData();

  formData.append("username", username);
  formData.append("password", password);

  return axiosInstance
    .post(`api/login/`, formData)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
};

export const GetUser = () => {
  return axiosWithAuthenticationInstance
    .get(`api/get_user_details/`)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
};

export const SignupUser = async (postData) => {
  let formData = new FormData();

  formData.append("full_name", postData.full_name);
  formData.append("phone_number", postData.phone_number);
  formData.append("governorate", postData.governorate);
  formData.append("neighborhood", postData.neighborhood);
  formData.append("password", postData.password);

  return axiosInstance
    .post(`api/create_customer_user/`, formData)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
};

export const SignupAgentUser = async (postData) => {
  let formData = new FormData();

  formData.append("full_name", postData.full_name);
  formData.append("phone_number", postData.phone_number);
  formData.append("governorate", postData.governorate);
  formData.append("neighborhood", postData.neighborhood);
  formData.append("password", postData.password);

  return axiosInstance
    .post(`api/create_agent_user/`, formData)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
};

export const SignupHGUser = async (postData) => {
  let formData = new FormData();

  formData.append("full_name", postData.full_name);
  formData.append("phone_number", postData.phone_number);
  formData.append("agent_phone_number", postData.agent_phone);
  formData.append("governorate", postData.governorate);
  formData.append("neighborhood", postData.neighborhood);
  formData.append("password", postData.password);

  return axiosInstance
    .post(`api/create_handicraft_user/`, formData)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
};
