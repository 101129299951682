import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetUser, LoginUser } from "./AuthAPI";
import { storeToLocalStorage } from "../Utility/CacheStore";

const initialState = {
  loading: true,
  token: null,
  user: null,
  errorMsg: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const loginAndThenGetUserData = (user) => async (dispatch) => {
  let result = await dispatch(LoginAsync(user));
  if (result.payload.success) {
    storeToLocalStorage("token", result.payload.token);
    return await dispatch(GetUserDataAsync(user.remember_me));
    // return result.payload;
  }
};

export const LoginAsync = createAsyncThunk("Auth/LoginAsync", async (user) => {
  const response = await LoginUser(user.username, user.password);
  // The value we return becomes the `fulfilled` action payload
  if (response.token) {
    return { success: true, token: response.token, user: response.user };
  } else {
    if (
      response.non_field_errors[0] ===
      "Unable to log in with provided credentials."
    ) {
      return {
        success: false,
        errorMsg: "البريد الالكتروني او الباسسورد غير صحيح",
      };
    } else {
      return {
        success: false,
        errorMsg: null,
      };
    }
  }
});

export const GetUserDataAsync = createAsyncThunk(
  "auth/GetUserDataAsync",
  async (remember) => {
    const response = await GetUser();
    // The value we return becomes the `fulfilled` action payload
    if (response && remember) {
      storeToLocalStorage("user", JSON.stringify(response));
    }
    return response;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    Login: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.loading = false;
    },
    Logout: (state) => {
      state.token = null;
      state.user = null;
      state.loading = false;
      state.errorMsg = "";
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //------------------------Login--------------------------//
      .addCase(LoginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(LoginAsync.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.token = action.payload.success ? action.payload.token : null;
          state.user = action.payload.success ? action.payload.user : null;
          state.errorMsg = "";
        } else {
          state.loading = false;
          state.errorMsg = action.payload.errorMsg;
        }
      })
      .addCase(LoginAsync.rejected, (state, action) => {
        state.loading = false;
      })
      //------------------------GetUser--------------------------//
      .addCase(GetUserDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetUserDataAsync.fulfilled, (state, action) => {
        debugger;
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(GetUserDataAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { Login, Logout } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state) => state.auth.user;
export const selectLoading = (state) => state.auth.loading;
export const selectToken = (state) => state.auth.token;
export const selectErrorMsg = (state) => state.auth.errorMsg;

export default authSlice.reducer;
