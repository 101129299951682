import axios from "axios";
import { getFromLocalStorage } from "./CacheStore";

// export const ip = "127.0.0.1:8000";
export const ip = "api.silverliningevent.com";
export const baseURL = `https://${ip}/`;

export const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 50000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export const axiosWithAuthenticationInstance = axios.create({
  baseURL: baseURL,
  timeout: 50000,
  headers: {
    "Content-Type": "multipart/form-data",
    accept: "application/json",
  },
});

axiosWithAuthenticationInstance.interceptors.request.use(
  async function (config) {
    const token = await getFromLocalStorage("token");
    config.headers.Authorization = `token ${token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosInstance;
