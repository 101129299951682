import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../Utility/axiosInstance";

function RegistrationPage() {
  const navigate = useNavigate();
  const [fullname, setfullname] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [job, setjob] = useState("");
  const [guestsnum, setguestsnum] = useState("");
  return (
    <div className="lightContainer">
      <div className="col center">
        <div className="flex">
          <label className="inputlable" htmlFor="fullname">
            الاسم الكامل
          </label>
          <input
            required
            name="fullname"
            dir="rtl"
            type="text"
            onChange={(event) => setfullname(event.target.value)}
            placeholder="اكتب"
            className="cusinput"
          />
          <label className="inputlable" htmlFor="phonenumber">
            رقم الهاتف
          </label>
          <input
            required
            name="phonenumber"
            dir="rtl"
            type="text"
            onChange={(event) => setphonenumber(event.target.value)}
            placeholder="اكتب"
            className="cusinput"
          />
          <label className="inputlable" htmlFor="job">
            المهنة
          </label>
          <input
            required
            name="job"
            dir="rtl"
            type="text"
            onChange={(event) => setjob(event.target.value)}
            placeholder="اكتب"
            className="cusinput"
          />
          <label className="inputlable" htmlFor="guestsnum">
            عدد المرافقين (الضيوف)
          </label>
          <select
            className="cusinput"
            dir="rtl"
            onChange={(event) => setguestsnum(event.target.value)}
            required
            name="guestsnum"
            style={{ width: "80%" }}
          >
            <option value="">اختر العدد</option>
            <option value="1">1</option>
            <option value="2">2</option>
          </select>
          <button
            onClick={() => {
              console.log(fullname, phonenumber, job, guestsnum);
              if (fullname && phonenumber && job) {
                let formData = new FormData();

                formData.append("fullname", fullname);
                formData.append("phonenumber", phonenumber);
                formData.append("job", job);
                if (guestsnum) {
                  formData.append("guestsnum", guestsnum);
                }

                axiosInstance
                  .post("api/register/", formData)
                  .then(function (response) {
                    if (response.data.guid) {
                      console.log(response.data.guid);
                      navigate("/qr", { state: { data: response.data } });
                    }
                  })
                  .catch(function (error) {
                    console.log(error.response);
                  });
              }
              // navigate("/qr");
            }}
            className="cusbutton"
          >
            تاكيد
          </button>
        </div>
      </div>
      <div className="col center">
        <div className="flex">
          <img
            alt="logo"
            src={require("../images/Union 2.png")}
            style={{ width: 100, alignSelf: "center", marginBlock: 10 }}
          />
          <span className="header1">Silver Lining</span>
          <span className="header2">منصة تسجيل الحضور</span>
        </div>
      </div>
    </div>
  );
}

export default RegistrationPage;
