import { useLocation, useNavigate } from "react-router-dom";
import { axiosWithAuthenticationInstance } from "../Utility/axiosInstance";

function ConfirmQR() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {}; // Access the parameter

  return (
    <div className="darkContainer">
      <div className="col center">
        <div className="flex">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              marginBlock: 50,
            }}
          >
            <div>
              <span className="header2">الاسم الكامل : </span>
              <span className="header2">{data?.fullname}</span>
            </div>
            <div>
              <span className="header2">رقم الهاتف : </span>
              <span className="header2">{data?.phonenumber}</span>
            </div>
            <div>
              <span className="header2">المهنة : </span>
              <span className="header2">{data?.job}</span>
            </div>
            <div>
              <span className="header2">عدد المرافقين : </span>
              <span className="header2">{data?.guestsnum}</span>
            </div>
          </div>
          <button
            onClick={() => {
              axiosWithAuthenticationInstance
                .put(`/api/confirm_attended/${data?.guid}/`)
                .then(function (response) {
                  if (response.data.success) {
                    console.log(response);
                    navigate("/read_qr");
                  }
                })
                .catch(function (error) {
                  console.log(error.response);
                });
            }}
            className="cusbutton"
          >
            تاكيد
          </button>
        </div>
      </div>
      <div className="col center">
        <div className="flex">
          <img
            alt="logo"
            src={require("../images/Union 2.png")}
            style={{ width: 100, alignSelf: "center", marginBlock: 10 }}
          />
          <span className="header1">Silver Lining</span>
          <span className="header2">تاكيد الحضور</span>
        </div>
      </div>
    </div>
  );
}

export default ConfirmQR;
