import "./App.css";
import {
  HashRouter,
  Route,
  // BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import IntroPage from "./screens/intro_page";
import RegistrationPage from "./screens/registration_page";
import QRPage from "./screens/qr_page";
import AdminLogin from "./screens/admin_login";
import ReadQR from "./screens/read_qr";
import ConfirmQR from "./screens/confirm_qr";
import PrivateRoutes from "./Utility/PrivateRoutes";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<IntroPage />} />
        <Route exact path="/registration" element={<RegistrationPage />} />
        <Route exact path="/qr" element={<QRPage />} />
        <Route exact path="/admin-login" element={<AdminLogin />} />
        <Route path="/read_qr" element={<PrivateRoutes />}>
          <Route exact path="/read_qr" element={<ReadQR />} />
        </Route>
        <Route path="/confirm_qr" element={<PrivateRoutes />}>
          <Route exact path="/confirm_qr" element={<ConfirmQR />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
