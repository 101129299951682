import { Scanner } from "@yudiel/react-qr-scanner";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeFromLocalStorage } from "../Utility/CacheStore";
import { useDispatch } from "react-redux";
import { Logout } from "./AuthSlice";
import { axiosWithAuthenticationInstance } from "../Utility/axiosInstance";

function ReadQR() {
  const [, setResult] = useState("");
  const [readQR, setReadQR] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleScan = (data) => {
    if (data) {
      console.log(data[0].rawValue);
      setResult(data[0].rawValue);
      axiosWithAuthenticationInstance
        .get(`/api/confirm_attended/${data[0].rawValue}/`)
        .then(function (response) {
          if (response.data.success) {
            console.log(response);
            navigate("/confirm_qr", { state: { data: response.data.data } });
          } else {
            setReadQR(false);
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
    }
  };

  const handelLogout = async () => {
    await removeFromLocalStorage("token");
    await dispatch(Logout());
  };

  return (
    <div className="darkContainer">
      <div className="col center">
        {readQR ? (
          <div className="flex">
            <div style={{ width: "50%" }}>
              <Scanner scanDelay={300} onScan={handleScan} />
            </div>
            <button
              onClick={() => {
                // window.location.reload();
                setReadQR(false);
              }}
              className="cusbutton"
            >
              الغاء
            </button>
          </div>
        ) : (
          <div className="flex">
            <button
              onClick={() => {
                setReadQR(true);
              }}
              className="cusbutton"
            >
              قراءة بطاقة الدعوة
            </button>
            <span
              onClick={handelLogout}
              style={{
                color: "white",
                fontSize: "12px",
                marginTop: 50,
                cursor: "pointer",
              }}
            >
              تسجيل الخروج
            </span>
          </div>
        )}
      </div>
      <div className="col center">
        <div className="flex">
          <img
            alt="logo"
            src={require("../images/Union 2.png")}
            style={{ width: 100, alignSelf: "center", marginBlock: 10 }}
          />
          <span className="header1">Silver Lining</span>
          <span className="header2">تاكيد الحضور</span>
        </div>
      </div>
    </div>
  );
}

export default ReadQR;
