
export const storeToLocalStorage = async (key,data) => {
  try {
    await localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
      console.log('Error storing',error)
  }
};
export const getFromLocalStorage = async (key) => {
    try {
      return await JSON.parse(localStorage.getItem(key));
    } catch (error) {
        console.log('Error get',error)
    }
  };
export const removeFromLocalStorage = async (key) => {
    try {
     await localStorage.removeItem(key);
    } catch (error) {
        console.log('Error remove',error)
    }
  };