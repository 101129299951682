import { useNavigate } from "react-router-dom";

function IntroPage() {
  const navigate = useNavigate();

  return (
    <div className="lightContainer">
      <div className="col center">
        <div>
          <button onClick={()=>{navigate('/registration')}} className="cusbutton">تسجيل</button>
        </div>
      </div>
      <div className="col center">
        <div className="flex">
          <img
            alt="logo"
            src={require("../images/Union 2.png")}
            style={{ width: 100, alignSelf: "center", marginBlock: 10 }}
          />
          <span className="header1">Silver Lining</span>
          <span className="header2">منصة تسجيل الحضور</span>
        </div>
      </div>
    </div>
  );
}

export default IntroPage;
