import QRCode from "qrcode.react";
import { useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

function QRPage() {
  // const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state || {}; // Access the parameter

  const upscaleCanvas = (canvas, scaleFactor, borderSize) => {
    // Create a new canvas element to draw the upscaled image
    const upscaledCanvas = document.createElement("canvas");
    const upscaledCtx = upscaledCanvas.getContext("2d");

     // Calculate the new dimensions including the border
  const borderOffset = borderSize * 2;
  upscaledCanvas.width = canvas.width * scaleFactor + borderOffset;
  upscaledCanvas.height = canvas.height * scaleFactor + borderOffset;

  // Fill the canvas with a white rectangle to create the border
  upscaledCtx.fillStyle = 'white';
  upscaledCtx.fillRect(0, 0, upscaledCanvas.width, upscaledCanvas.height);

  // Draw the original canvas content onto the new canvas at the larger size, centered within the border
  upscaledCtx.drawImage(canvas, borderSize, borderSize, canvas.width * scaleFactor, canvas.height * scaleFactor);

  // Return the new canvas element
  return upscaledCanvas;
  };

  const saveQR = () => {
    const canvas = document.querySelector("canvas");
    const scaleFactor = 4; // Replace with your desired scale factor
    const upscaledCanvas = upscaleCanvas(canvas, scaleFactor,30);
    const pngUrl = upscaledCanvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QRCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="darkContainer">
      <div className="col center">
        <div className="flex">
          <span className="header2">
            قم بحفظ هذه الدعوة , سيتم طلبها عند الحضور
          </span>
          <QRCode  style={{ marginBlock: 20 }} value={data?.guid} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span className="header2">الاسم الكامل : </span>
              <span className="header2">{data?.fullname}</span>
            </div>
            <div>
              <span className="header2">رقم الهاتف : </span>
              <span className="header2">{data?.phonenumber}</span>
            </div>
            <div>
              <span className="header2">المهنة : </span>
              <span className="header2">{data?.job}</span>
            </div>
            <div>
              <span className="header2">عدد المرافقين : </span>
              <span className="header2">{data?.guestsnum}</span>
            </div>
          </div>
          <button onClick={saveQR} className="cusbutton">
            حفظ الدعوة كصورة
          </button>
          <span className="header2">ملاحضة : يرجى عدم اصطحاب الاطفال</span>
        </div>
      </div>
      <div className="col center">
        <div className="flex">
          <img
            alt="logo"
            src={require("../images/Union 2.png")}
            style={{ width: 100, alignSelf: "center", marginBlock: 10 }}
          />
          <span className="header1">Silver Lining</span>
          <span className="header2">منصة تسجيل الحضور</span>
        </div>
      </div>
    </div>
  );
}

export default QRPage;
