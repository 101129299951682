import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../Utility/CacheStore";
import { useDispatch } from "react-redux";
import { Login, loginAndThenGetUserData } from "./AuthSlice";

function AdminLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");

  const setLoggedInUser = async () => {
    const token = await getFromLocalStorage("token");
    const user = await getFromLocalStorage("user");
    if (token) {
      await dispatch(Login({ token: token, user: JSON.parse(user) }));
    }
  };

  useEffect(() => {
    async function CheckUser() {
      await setLoggedInUser();
    }
    CheckUser();
  }, []);
  return (
    <div className="darkContainer">
      <div className="col center">
          <div className="flex">
            <label className="inputlable" htmlFor="username">
              البريد الالكتروني
            </label>
            <input
              required
              name="username"
              dir="rtl"
              type="text"
              onChange={(event) => setusername(event.target.value)}
              placeholder="اكتب"
              className="cusinput"
            />
            <label className="inputlable" htmlFor="password">
              الرمز السري
            </label>
            <input
              required
              name="password"
              dir="rtl"
              type="text"
              onChange={(event) => setpassword(event.target.value)}
              placeholder="اكتب"
              className="cusinput"
            />
            <button
              onClick={() => {
                console.log(username, password);
                dispatch(
                  loginAndThenGetUserData({
                    username: username,
                    password: password,
                    remember_me: true,
                  })
                ).then(() => {
                  navigate("/read_qr");
                });
              }}
              className="cusbutton"
            >
              دخول
            </button>
          </div>
      </div>
      <div className="col center">
        <div className="flex">
          <img
            alt="logo"
            src={require("../images/Union 2.png")}
            style={{ width: 100, alignSelf: "center", marginBlock: 10 }}
          />
          <span className="header1">Silver Lining</span>
          <span className="header2">تاكيد الحضور</span>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
